.login-card-container{
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    margin-bottom: 1rem;
    min-height: 200px;
    height: auto;

    .login-card-content{
        background-image: url('../../../img/Rectangle77.png');
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        width: 150px;
        background-position: center;

    }

    @media screen and (max-width:468px) {
        grid-template-columns: 1fr;

        .login-card-content{
            width: 100%;
            height: 80px;
            background-image: url('../../../img/Rectangle\ 76.png');
        }
    }

    @media screen and (min-width: 468px) and( max-width: 1199px) {
        grid-template-columns: 1fr;

        .login-card-content{
            width: 100%;
            height: 135px;
            background-image: url('../../../img/Rectangle\ 76.png');
        }
    }

    @media screen and (max-width:1199px) {
        & {
            padding: 1rem;
            border: 1px solid rgba(210, 217, 225, 0.30);
            box-shadow:  0px 4px 0px rgba(78, 88, 100, 0.18);
        }
}}

.login-card-footer{
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;

    .title{
        text-align: center;

        p:first-child{
            font-size: 24px;
            color: #4E5864;
            font-weight: 400;
            margin: 0
        }
    }

    @media screen and (max-width:1199px) {
        .title{
            p:first-child{
                font-size: 17px;
                font-weight: 600;
            }
        }
    }
}

.login-button-container{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;

    button{
        height: 36px;
        width: 200px;
        cursor: pointer;
    }

    button:first-child{
        background-color: #E8512F;
        color: white;
        border-radius: 4px;
        border: none;
    }

    button:last-child{
        background-color: #fff;
        color: #000;
        border-radius: 4px;
        border: 1px solid #E8512F;
    }

    @media screen and (max-width:1199px) {
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        width: 100%;

        button{
            width: 100%;
        }
        
    }
}

