.form-check{
    margin-bottom: 20px;
    font-size: 12px;
    gap: 0;
    font-style: italic;
    display: flex;
    align-items: center;
}
.form-check > input[type="checkbox"]{
    accent-color: #e8512f;
    cursor: pointer;
    margin-top: 0;

}
.form-check > label{
    cursor: pointer;
}
