.div {
  background: #1c1c1c;

  width: 100%;
  align-items: center;
  display: flex;
  color: white;
  padding-left: 20px;
  font-family: Proxima Nova, -apple-system, Roboto, Helvetica, sans-serif;
  padding-right: 20px;

  flex-direction: column;
}
.div img{
  cursor: pointer;
}
.divider{
  width: 100%;
  height: 1px;
  background-color: #FFF;

  opacity: 0.2;
}
.logo_container{
  display: flex;
  flex-direction: column;
}
.layout {
  max-width: var(--max-width);
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;

}
.section1{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 20px;
}
.gridContainer {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  row-gap: 15px;
  column-gap: 100px;
  place-items: center;
}
.contactus{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.contactus a, .contactus p{
  color: #fff;
}

.contactus a:hover, .contactus p:hover{
  color: $tripper2;
}
.contactus a, .contactus p{
  color: #fff;
}

.contactus p {
  cursor: pointer;
}
.contactus> p > a{
  color: inherit;
text-decoration: none;
}
.socialIconSection{
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: space-between;
}

.logo {
  object-fit: cover;
  object-position: center;
  width: 204px;
  height: 54px;
}

.socialIcon{
  object-fit:fill;
  width: 16px;
  height: 16px;
}

.twitterIcon{
  object-fit:fill;
  width: 19px;
  height: 16px;
}
.logo_container > p {
  color: #fff;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  opacity: 0.5;
  font-size: 14px;
  margin-top: 18px;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 1px;
  align-self: stretch;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.navigational_links{
  cursor: pointer;
  color: $white !important;

  &:hover{
    color: $tripper2 !important;
  }
}

.section2 {
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 18.5px;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.section2 > p, .section2 a{
  text-align: center;
  opacity: 0.5 !important;
  color: #FFF !important;
}

.section2 a:hover{
  color: $tripper2 !important;
  opacity: 1 !important;
}

.disclaimer{
  padding: 1rem;
  text-align: center;
  opacity: 0.5;
}

@media (max-width: 900px) {
  .section1{
      flex-direction: column;
      align-items: center;
  }
  .gridContainer{
      margin-top: 20px;
      grid-template-rows: repeat(1, 1fr);
      grid-template-columns: repeat(1, 1fr);
      row-gap: 15px;

      place-items: center;

      a{
        color: $white !important;
      }
  }
  .contactus{
      margin-top: 20px;
      align-items: center;
      gap: 15px;

  }
  .section2{
      flex-direction: column;
      align-items: center;
      gap: 15px;
      margin-top: 20px;
  }
}
