.itinerary-card{
    display: grid;

    .row{
        display: grid;
        grid-template-columns: 1fr auto;
        padding: 1rem;
        align-items: center;

        p.title{
            font-size: 24px;
            color: #4E5864;
            font-weight: 600;
            text-transform: capitalize;
        }

    .end{
        justify-self: end;
    }

    .routes{
        display: grid;
        grid-template-columns: repeat(3, auto);
        align-items: center;

        p{
            text-transform: capitalize;
        }

        .time {
            color: #4E5864;
        }

        img{
            margin: 0 3px;
        }
    }

    .date{
        color: #4E5864;
        font-size: 18px;
        font-weight: 700;

        @media screen and (max-width: 1199px) {
            font-size: 12px;
        }

    }

    @media screen and (max-width: 1199px) {
        align-items: end;
    }

    @media screen and (max-width: 360px) {
        &.mobile-row{
            padding: 5px;
            

            .routes {
                p, .time{
                    font-size: 11px;
                }

                img{
                    margin: 0 2px;
                }

                &.gap{
                    grid-gap: 7px;
                }
            }

        }
    }
    }
}

.border-bottom{
    border-bottom: 1px solid #D2D9E1;;
}