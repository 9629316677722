// TripperBus - _bootstrap-overrides.scss

.toast {
	opacity: 1!important;
}

.error-icon {
	background-image:none;
	background-color: #f44336;
}
.success-icon {
	background-image:none;
	background-color: #4CAF50;
}