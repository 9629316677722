// TripperBus - _variables.scss
//
// COLOR SCHEMES
//
$black: #000 !default;
$dark: #1B1E21;
$white: #fff !default;
$gray: #eee !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$tripper1: #1b1e21 !default;
$tripper2: #e8512f !default;
$tripper3: #ffb300 !default;
$tripper4: #41bbc0 !default;
$tripper5: #299599 !default;
$tripper6: #29333c !default;
$tripper7: #439adb !default;
$tripper8: #ea305c !default;
$tripper-gray: #717374 !default;
$tripper-gray-new: #4E5864 !default;
$tripper-light-gray-new: #F9FAFB !default;
$tripper-gray2: #f8fafb !default;
$tripper-gray3: #848484 !default;
$tripper-gray4: #dfe6e6 !default;
$tripper-gray5: #e1e8e8 !default;
$tripper-gray-dark: #33383c !default;
$tripper-gray-dark2: #363636 !default;
$tripper-gray-dark3: #555 !default;
$tripper-highlight: #12f6ff !default; //
$form-lable-color:#858585 !default;
//
// FONTS
//
$font-stack: 'Proxima Nova';
