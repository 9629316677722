@font-face {
	font-family: "Proxima Nova Cn";
	src: url("../fonts/ProximaNovaCond-LightIt.eot");
	src: local("Proxima Nova Condensed Light Italic"), local("ProximaNovaCond-LightIt"), url("../fonts/ProximaNovaCond-LightIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-LightIt.woff") format("woff"), url("../fonts/ProximaNovaCond-LightIt.ttf") format("truetype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("../fonts/ProximaNova-LightIt.eot");
	src: local("Proxima Nova Light Italic"), local("ProximaNova-LightIt"), url("../fonts/ProximaNova-LightIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-LightIt.woff") format("woff"), url("../fonts/ProximaNova-LightIt.ttf") format("truetype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova Cn";
	src: url("../fonts/ProximaNovaCond-Regular.eot");
	src: local("Proxima Nova Condensed Regular"), local("ProximaNovaCond-Regular"), url("../fonts/ProximaNovaCond-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-Regular.woff") format("woff"), url("../fonts/ProximaNovaCond-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("../fonts/ProximaNova-Extrabld.eot");
	src: local("Proxima Nova Extrabold"), local("ProximaNova-Extrabld"), url("../fonts/ProximaNova-Extrabld.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Extrabld.woff") format("woff"), url("../fonts/ProximaNova-Extrabld.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova Cn";
	src: url("../fonts/ProximaNovaCond-Black.eot");
	src: local("Proxima Nova Condensed Black"), local("ProximaNovaCond-Black"), url("../fonts/ProximaNovaCond-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-Black.woff") format("woff"), url("../fonts/ProximaNovaCond-Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova Cn";
	src: url("../fonts/ProximaNovaCond-ExtrabldIt.eot");
	src: local("Proxima Nova Condensed Extrabold Italic"), local("ProximaNovaCond-ExtrabldIt"), url("../fonts/ProximaNovaCond-ExtrabldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-ExtrabldIt.woff") format("woff"), url("../fonts/ProximaNovaCond-ExtrabldIt.ttf") format("truetype");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("../fonts/ProximaNova-Regular.eot");
	src: local("Proxima Nova Regular"), local("ProximaNova-Regular"), url("../fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Regular.woff") format("woff"), url("../fonts/ProximaNova-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("../fonts/ProximaNova-Semibold.eot");
	src: local("Proxima Nova Semibold"), local("ProximaNova-Semibold"), url("../fonts/ProximaNova-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Semibold.woff") format("woff"), url("../fonts/ProximaNova-Semibold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova ExCn";
	src: url("../fonts/ProximaNovaExCn-Thin.eot");
	src: local("Proxima Nova Extra Condensed Thin"), local("ProximaNovaExCn-Thin"), url("../fonts/ProximaNovaExCn-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-Thin.woff") format("woff"), url("../fonts/ProximaNovaExCn-Thin.ttf") format("truetype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova Cn";
	src: url("../fonts/ProximaNovaCond-Semibold.eot");
	src: local("Proxima Nova Condensed Semibold"), local("ProximaNovaCond-Semibold"), url("../fonts/ProximaNovaCond-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-Semibold.woff") format("woff"), url("../fonts/ProximaNovaCond-Semibold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova ExCn";
	src: url("../fonts/ProximaNovaExCn-SemiboldIt.eot");
	src: local("Proxima Nova Extra Condensed Semibold Italic"), local("ProximaNovaExCn-SemiboldIt"), url("../fonts/ProximaNovaExCn-SemiboldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-SemiboldIt.woff") format("woff"), url("../fonts/ProximaNovaExCn-SemiboldIt.ttf") format("truetype");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova ExCn";
	src: url("../fonts/ProximaNovaExCn-Semibold.eot");
	src: local("Proxima Nova Extra Condensed Semibold"), local("ProximaNovaExCn-Semibold"), url("../fonts/ProximaNovaExCn-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-Semibold.woff") format("woff"), url("../fonts/ProximaNovaExCn-Semibold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("../fonts/ProximaNova-ThinIt.eot");
	src: local("Proxima Nova Thin Italic"), local("ProximaNova-ThinIt"), url("../fonts/ProximaNova-ThinIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-ThinIt.woff") format("woff"), url("../fonts/ProximaNova-ThinIt.ttf") format("truetype");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova Cn";
	src: url("../fonts/ProximaNovaCond-Thin.eot");
	src: local("Proxima Nova Condensed Thin"), local("ProximaNovaCond-Thin"), url("../fonts/ProximaNovaCond-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-Thin.woff") format("woff"), url("../fonts/ProximaNovaCond-Thin.ttf") format("truetype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova Cn";
	src: url("../fonts/ProximaNovaCond-RegularIt.eot");
	src: local("Proxima Nova Condensed Regular Italic"), local("ProximaNovaCond-RegularIt"), url("../fonts/ProximaNovaCond-RegularIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-RegularIt.woff") format("woff"), url("../fonts/ProximaNovaCond-RegularIt.ttf") format("truetype");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova ExCn";
	src: url("../fonts/ProximaNovaExCn-ThinIt.eot");
	src: local("Proxima Nova Extra Condensed Thin Italic"), local("ProximaNovaExCn-ThinIt"), url("../fonts/ProximaNovaExCn-ThinIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-ThinIt.woff") format("woff"), url("../fonts/ProximaNovaExCn-ThinIt.ttf") format("truetype");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("../fonts/ProximaNova-SemiboldIt.eot");
	src: local("Proxima Nova Semibold Italic"), local("ProximaNova-SemiboldIt"), url("../fonts/ProximaNova-SemiboldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-SemiboldIt.woff") format("woff"), url("../fonts/ProximaNova-SemiboldIt.ttf") format("truetype");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("../fonts/ProximaNova-RegularIt.eot");
	src: local("Proxima Nova Regular Italic"), local("ProximaNova-RegularIt"), url("../fonts/ProximaNova-RegularIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-RegularIt.woff") format("woff"), url("../fonts/ProximaNova-RegularIt.ttf") format("truetype");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova Cn";
	src: url("../fonts/ProximaNovaCond-Extrabld.eot");
	src: local("Proxima Nova Condensed Extrabold"), local("ProximaNovaCond-Extrabld"), url("../fonts/ProximaNovaCond-Extrabld.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-Extrabld.woff") format("woff"), url("../fonts/ProximaNovaCond-Extrabld.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova ExCn";
	src: url("../fonts/ProximaNovaExCn-ExtrabldIt.eot");
	src: local("Proxima Nova Extra Condensed Extrabold Italic"), local("ProximaNovaExCn-ExtrabldIt"), url("../fonts/ProximaNovaExCn-ExtrabldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-ExtrabldIt.woff") format("woff"), url("../fonts/ProximaNovaExCn-ExtrabldIt.ttf") format("truetype");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova ExCn";
	src: url("../fonts/ProximaNovaExCn-BlackIt.eot");
	src: local("Proxima Nova Extra Condensed Black Italic"), local("ProximaNovaExCn-BlackIt"), url("../fonts/ProximaNovaExCn-BlackIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-BlackIt.woff") format("woff"), url("../fonts/ProximaNovaExCn-BlackIt.ttf") format("truetype");
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova ExCn";
	src: url("../fonts/ProximaNovaExCn-LightIt.eot");
	src: local("Proxima Nova Extra Condensed Light Italic"), local("ProximaNovaExCn-LightIt"), url("../fonts/ProximaNovaExCn-LightIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-LightIt.woff") format("woff"), url("../fonts/ProximaNovaExCn-LightIt.ttf") format("truetype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova Cn";
	src: url("../fonts/ProximaNovaCond-ThinIt.eot");
	src: local("Proxima Nova Condensed Thin Italic"), local("ProximaNovaCond-ThinIt"), url("../fonts/ProximaNovaCond-ThinIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-ThinIt.woff") format("woff"), url("../fonts/ProximaNovaCond-ThinIt.ttf") format("truetype");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova ExCn";
	src: url("../fonts/ProximaNovaExCn-Light.eot");
	src: local("Proxima Nova Extra Condensed Light"), local("ProximaNovaExCn-Light"), url("../fonts/ProximaNovaExCn-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-Light.woff") format("woff"), url("../fonts/ProximaNovaExCn-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova Cn";
	src: url("../fonts/ProximaNovaCond-BoldIt.eot");
	src: local("Proxima Nova Condensed Bold Italic"), local("ProximaNovaCond-BoldIt"), url("../fonts/ProximaNovaCond-BoldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-BoldIt.woff") format("woff"), url("../fonts/ProximaNovaCond-BoldIt.ttf") format("truetype");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova ExCn";
	src: url("../fonts/ProximaNovaExCn-Extrabld.eot");
	src: local("Proxima Nova Extra Condensed Extrabold"), local("ProximaNovaExCn-Extrabld"), url("../fonts/ProximaNovaExCn-Extrabld.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-Extrabld.woff") format("woff"), url("../fonts/ProximaNovaExCn-Extrabld.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova ExCn";
	src: url("../fonts/ProximaNovaExCn-BoldIt.eot");
	src: local("Proxima Nova Extra Condensed Bold Italic"), local("ProximaNovaExCn-BoldIt"), url("../fonts/ProximaNovaExCn-BoldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-BoldIt.woff") format("woff"), url("../fonts/ProximaNovaExCn-BoldIt.ttf") format("truetype");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("../fonts/ProximaNova-Black.eot");
	src: local("Proxima Nova Black"), local("ProximaNova-Black"), url("../fonts/ProximaNova-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Black.woff") format("woff"), url("../fonts/ProximaNova-Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("../fonts/ProximaNova-Bold.eot");
	src: local("Proxima Nova Bold"), local("ProximaNova-Bold"), url("../fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Bold.woff") format("woff"), url("../fonts/ProximaNova-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova ExCn";
	src: url("../fonts/ProximaNovaExCn-Bold.eot");
	src: local("Proxima Nova Extra Condensed Bold"), local("ProximaNovaExCn-Bold"), url("../fonts/ProximaNovaExCn-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-Bold.woff") format("woff"), url("../fonts/ProximaNovaExCn-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("../fonts/ProximaNovaT-Thin.eot");
	src: local("Proxima Nova Thin"), local("ProximaNovaT-Thin"), url("../fonts/ProximaNovaT-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaT-Thin.woff") format("woff"), url("../fonts/ProximaNovaT-Thin.ttf") format("truetype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("../fonts/ProximaNova-BoldIt.eot");
	src: local("Proxima Nova Bold Italic"), local("ProximaNova-BoldIt"), url("../fonts/ProximaNova-BoldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-BoldIt.woff") format("woff"), url("../fonts/ProximaNova-BoldIt.ttf") format("truetype");
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova Cn";
	src: url("../fonts/ProximaNovaCond-Bold.eot");
	src: local("Proxima Nova Condensed Bold"), local("ProximaNovaCond-Bold"), url("../fonts/ProximaNovaCond-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-Bold.woff") format("woff"), url("../fonts/ProximaNovaCond-Bold.ttf") format("truetype");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova ExCn";
	src: url("../fonts/ProximaNovaExCn-Black.eot");
	src: local("Proxima Nova Extra Condensed Black"), local("ProximaNovaExCn-Black"), url("../fonts/ProximaNovaExCn-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-Black.woff") format("woff"), url("../fonts/ProximaNovaExCn-Black.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova ExCn";
	src: url("../fonts/ProximaNovaExCn-RegularIt.eot");
	src: local("Proxima Nova Extra Condensed Regular Italic"), local("ProximaNovaExCn-RegularIt"), url("../fonts/ProximaNovaExCn-RegularIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-RegularIt.woff") format("woff"), url("../fonts/ProximaNovaExCn-RegularIt.ttf") format("truetype");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova Cn";
	src: url("../fonts/ProximaNovaCond-Light.eot");
	src: local("Proxima Nova Condensed Light"), local("ProximaNovaCond-Light"), url("../fonts/ProximaNovaCond-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-Light.woff") format("woff"), url("../fonts/ProximaNovaCond-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("../fonts/ProximaNova-ExtrabldIt.eot");
	src: local("Proxima Nova Extrabold Italic"), local("ProximaNova-ExtrabldIt"), url("../fonts/ProximaNova-ExtrabldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-ExtrabldIt.woff") format("woff"), url("../fonts/ProximaNova-ExtrabldIt.ttf") format("truetype");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova Cn";
	src: url("../fonts/ProximaNovaCond-BlackIt.eot");
	src: local("Proxima Nova Condensed Black Italic"), local("ProximaNovaCond-BlackIt"), url("../fonts/ProximaNovaCond-BlackIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-BlackIt.woff") format("woff"), url("../fonts/ProximaNovaCond-BlackIt.ttf") format("truetype");
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova ExCn";
	src: url("../fonts/ProximaNovaExCn-Regular.eot");
	src: local("Proxima Nova Extra Condensed Regular"), local("ProximaNovaExCn-Regular"), url("../fonts/ProximaNovaExCn-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaExCn-Regular.woff") format("woff"), url("../fonts/ProximaNovaExCn-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("../fonts/ProximaNova-Light.eot");
	src: local("Proxima Nova Light"), local("ProximaNova-Light"), url("../fonts/ProximaNova-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-Light.woff") format("woff"), url("../fonts/ProximaNova-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Proxima Nova Cn";
	src: url("../fonts/ProximaNovaCond-SemiboldIt.eot");
	src: local("Proxima Nova Condensed Semibold Italic"), local("ProximaNovaCond-SemiboldIt"), url("../fonts/ProximaNovaCond-SemiboldIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNovaCond-SemiboldIt.woff") format("woff"), url("../fonts/ProximaNovaCond-SemiboldIt.ttf") format("truetype");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: "Proxima Nova";
	src: url("../fonts/ProximaNova-BlackIt.eot");
	src: local("Proxima Nova Black Italic"), local("ProximaNova-BlackIt"), url("../fonts/ProximaNova-BlackIt.eot?#iefix") format("embedded-opentype"), url("../fonts/ProximaNova-BlackIt.woff") format("woff"), url("../fonts/ProximaNova-BlackIt.ttf") format("truetype");
	font-weight: 900;
	font-style: italic;
}
