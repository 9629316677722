.not-found{
    display: grid;
    .grid{
        display: grid;
        min-height: 700px;

        grid-template-columns: 1fr 1fr;
        align-items: center;
        height: 100%;
        justify-items: center;

        .left{
            display: grid;
            align-items: center;
            justify-items: self-start;
            h1{
                font-size: 250px;
                line-height: 200px;
            }

            h3{
                font-size: 35px;
                font-weight: 800;
            }

            p{
                font-size: 16px;
                padding: 1rem 0;
                @media screen and (max-width: 991px) {
                    text-align: center;  
                }
            }

            h1, h3{
                margin: 0;
            }

            .tripper-button{
                display: inline-flex;
                height: 51px;
                padding: 10.2px 30.6px;
                justify-content: center;
                align-items: center;
                gap: 5.1px;
                span{
                    line-height: 0;
                    color: #fff;
                    font-weight: 600;
                }
            }

        }

        
    @media screen and (max-width: 991px) {
            & {
                grid-template-columns: 1fr;
                padding: 2rem;

                .left{
                    justify-items: center;
                    h1{
                        font-size: 130px;
                        line-height: 150px;
                    }

                    h3{
                        font-size: 20px;
                    }
                }

                .right{
                    img{
                        height: 220px;
                        width: 300px;
                    }
                }
            }
        }
    }

}