// TripperBus - _header.scss

header {
	&.masthead {
    position: relative;
		min-height: 450px;
		height: 100%;
		background-image: url('../img/header.png');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;

		h1 {
			font-size: 3rem;
			line-height: 3rem;
		}

		h2 {
			@include body-font;
			font-size: 1.3rem;
    }

    &::before {
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      background-color: #29333c;
      opacity: .75;
    }
	}
}

@media (min-width:1200px){
  .navbar .container{
    max-width: 1150px;
  }
}