// TripperBus - _mixins.scss
@mixin body-font {
	font-family: 'Proxima Nova', Arial, sans-serif;
}

@mixin body-font-cn {
	font-family: 'Proxima Nova Cn', Arial, sans-serif;
}

@mixin heading-font {
	font-family: 'Proxima Nova', Arial, sans-serif;
	font-weight: 700;
}

@keyframes glowing {
	0% {
		box-shadow: 0 0 5px 1px transparentize($white, .65);
	}

	20% {
		box-shadow: 0 0 10px 4px transparentize($white, .65);
	}

	40% {
		box-shadow: 0 0 10px 4px transparentize($white, .65);
	}

	60% {
		box-shadow: 0 0 10px 4px transparentize($white, .65);
	}

	100% {
		box-shadow: 0 0 5px 1px transparentize($white, .65);
	}
}

@keyframes glowing2 {
	0% {
		box-shadow: 0 0 5px 1px transparentize($tripper-highlight, .25);
	}

	20% {
		box-shadow: 0 0 10px 4px transparentize($tripper-highlight, .25);
	}

	40% {
		box-shadow: 0 0 10px 4px transparentize($tripper-highlight, .25);
	}

	60% {
		box-shadow: 0 0 10px 4px transparentize($tripper-highlight, .25);
	}

	100% {
		box-shadow: 0 0 5px 1px transparentize($tripper-highlight, .25);
	}
}
